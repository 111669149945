export class UserAddress {
    label: String;
    title: string;
    city: number;
    state: number;
    area: number;
    cityName: String;
    stateName: String;
    areaName: String;
    pinCode: String;
    addressId: number;
    address1: String;
    address2: String;
    addressType: number;
    referenceId: String;
    location: Locations;
    localityList: any[];
    public doorNo: string;
	public landmark: string;
    locationCoordinates: LocationCoordinates;

    name: String;
    mobile: String;

    public citySlug: string;
    public defaultSelection: boolean;
}
export class Locations {
    static LONGITUDE = 0;
    static LATITUDE = Locations.LONGITUDE + 1;

    type: String = "Point";
    coordinates: number[];

}
export class LocationCoordinates {
    lat: number;
    lon: number;
}
export class SearchLocationAddress {
    formatted_address: string;
    city: string;
    pincode: string;
    addressDetails: any;
    country?: string;
    lat?: string;
    lang?: string;
}