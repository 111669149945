import AxiosService from '../../../base/portal_env_config/axiosService';
import { paths } from '../../../base/portal_env_config/urlconstants';
import { UserAddress, Locations } from '../../../model/location/useraddress';
import Validations from '../../../base/utils/validations';
import StorageUtil from '../../../auth/util/storageUtil';

export default class LocationDropdwnService {

    private static AVERAGE_RADIUS_OF_EARTH_KM = 6371;
    private static _location: any;
    private static _state: any = {};
    public static setState(state) { this._state = state; }

    public static getSerLocation(): any {
        if (typeof window !== "undefined") {
            return LocationDropdwnService._location || this._state?.location?.selectedLocation || JSON.parse(StorageUtil.getUnencryptedLocalStorageData('userlocation'));
        } else {
            return null;
        }
    }

    public static setSerLocation(value: any) {
        if (typeof window !== "undefined") {
            LocationDropdwnService._location = value;
        } else {
            return null;
        }
    }

    public static async getLocByLatLon(latLon): Promise<any> {
        return AxiosService.httpPost(paths.GET_LOC_BY_LATLON, latLon).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getLocByAddress(searchTerm): Promise<any> {
        return AxiosService.httpGet(paths.GET_SEARCHED_ADDRESS, { searchTerm: searchTerm }).then((res) => {
            return Promise.resolve(res);
        }).catch(err => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getStateCityByPinCode(pinCode: any): Promise<any> {
        return AxiosService.httpGet(paths.GET_STATE_CITY_BY_PINCODE, { pincode: pinCode }).then((res) => {
            return Promise.resolve(res.data);
        }).catch((err) => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async saveAddress(address): Promise<any> {
        return AxiosService.httpPost(paths.UPDATEADDRESS, address).then((res) => {
            return Promise.resolve(res.data);
        }).catch((err) => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    static getFormatedAddress(addres) {
        //(addres?.landmark ? addres?.landmark + ", " : '') +
        // Removed landmark as we are getting fromatted address from map and appending in landmark
        return addres ? (addres.address1 ? addres.address1 + ', ' : '') + (addres.address2 ? addres.address2 + ', ' : '') +
            (addres.areaName ? addres.areaName + ', ' : '') + (addres.cityName ? addres.cityName + ', ' : '') +
            (addres.stateName ? addres.stateName + ', ' : '') + (addres.pinCode ? addres.pinCode : '') : '';
    }

    static searchByPinCode(pinCode: any, selectedAdd = new UserAddress()) {
        return this.getStateCityByPinCode(pinCode).then((response: any) => {
            if (response.length > 0) {
                // let selectedAdd: UserAddress = clone(selectedAddress);
                let value = response[0]?.localityList[0];
                selectedAdd.areaName = response[0].localityList[0].name;
                selectedAdd.title = response[0].localityList[0].name;
                selectedAdd.pinCode = response[0].pinCode;
                selectedAdd.area = response[0].localityList[0].id;
                selectedAdd.city = response[0].cityId;
                selectedAdd.cityName = response[0].cityName;
                selectedAdd.state = response[0].stateId;
                selectedAdd.stateName = response[0].stateName;
                selectedAdd.title = response[0].localityList[0].name;
                selectedAdd.localityList = response[0].localityList;
                selectedAdd.location = new Locations();
                selectedAdd.locationCoordinates = { "lat": value.lat, "lon": value.lon };
                selectedAdd.location.coordinates = [value.lon, value.lat];
                return Promise.resolve({ isError: false, errMsg: '', data: selectedAdd });
            } else return Promise.resolve({ isError: true, errMsg: 'Invalid PinCode !', data: undefined });
        }).catch((err) => { if (err) return Promise.resolve({ isError: true, errMsg: 'Connection issue !!', data: undefined }) });
    }

    private static toRad(degrees) {
        var pi = Math.PI;
        return degrees * (pi / 180);
    }

    public static calculateDistanceInKilometer(lat1, lon1, lat2, lon2, unit) {
        if ((lat1 == lat2) && (lon1 == lon2)) {
            return 0;
        }
        else {
            var R = 6371; // km
            var dLat = this.toRad(lat2 - lat1);
            var dLon = this.toRad(lon2 - lon1);
            lat1 = this.toRad(lat1);
            lat2 = this.toRad(lat2);

            var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c;
            return Validations?.validateFloat(d) + ' ' + unit;
        }
    }
}
export const clone = (data) => { return { ...data } };